<template>
  <div>
    <b-modal
      id="modal-modify-change-flight-select-trip"
      :title="$t('reservation.changeFlight')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      footer-class="d-flex justify-content-center"
      size="lg"
      no-close-on-backdrop
      @show="showHandle"
    >
      <template #modal-footer>
        <b-button
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="handleCloseModal"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient  border-0"
          :disabled="!indexSelectedFlightToChange.length"
          pill
          @click="handleSearchAirline"
        >
          <span class="">{{ $t('reservation.confirm') }}</span>
        </b-button>
      </template>

      <div class="text-heading-5 mb-1">
        Chọn hành trình muốn thay đổi:
      </div>

      <div>
        <b-form-group>
          <template #label>
            <b-form-checkbox
              v-if="!isProduction && getBookingData.itineraries.length > 1 && ['VN1A', 'VN1A_MT'].includes(getBookingData.source) && ['RT'].includes(getBookingData.flightType)"
              v-model="allSelected"
              aria-describedby="flights"
              aria-controls="flights"
              @change="toggleAll"
            >
              <div class="text-body-2">
                {{ allSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
              </div>
            </b-form-checkbox>
          </template>

          <template #default="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="checkbox-group-cancel-flight"
              v-model="indexSelectedFlightToChange"
              :aria-describedby="ariaDescribedby"
              stacked
              name="flights"
            >
              <div
                v-for="(itinerary, indexItinerary) in getBookingData.itineraries"
                :key="indexItinerary"
                class="d-flex"
              >
                <b-form-checkbox
                  :value="indexItinerary"
                  :disabled="disableUncheck(indexItinerary)"
                >
                  <div
                    v-for="(segment, indexSegment) in itinerary"
                    :key="indexSegment"
                    class=""
                  >
                    <span class="text-dark font-weight-bolder pl-md-1">{{ convertShortTrip(segment) }}</span>
                  </div>
                </b-form-checkbox>
              </div>
            </b-form-checkbox-group>
          </template>
        </b-form-group>
      </div>

      <b-alert
        v-if="!['VN1A', 'VN1A_MT'].includes(getBookingData.source)"
        show
        variant="warning"
        class="px-1 py-50 font-weight-bolder font-medium-2 text-center"
      >
        Chỉ hỗ trợ đổi từng hành trình
      </b-alert>
    </b-modal>

    <ModalChangeFlightSearch />
  </div>
</template>

<script>
import {
  BModal, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BButton, BAlert,
} from 'bootstrap-vue'
import { ref, computed, watch } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import { formatCurrency, convertISODateTime, convertShortTrip } from '@/@core/utils/filter'
import store from '@/store'

import useReservationHandle from '@reservation/useReservationHandle'

import ModalChangeFlightSearch from '../search/index.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BButton,
    BAlert,

    ModalChangeFlightSearch,
  },
  setup() {
    const indexSelectedFlightToChange = ref([])
    const indexSelectedFlightToChangeComputed = computed(() => [...indexSelectedFlightToChange.value].sort())

    const {
      getBookingData,
      delay,
      isProduction,
    } = useReservationHandle()

    const allSelected = ref(false)

    function toggleAll(checked) {
      indexSelectedFlightToChange.value = checked
        ? getBookingData.value.itineraries.map((item, index) => index).slice()
        : []
    }

    watch(indexSelectedFlightToChange, newValue => {
      if (newValue.length === 0) {
        allSelected.value = false
      } else if (newValue.length === getBookingData.value.itineraries.map((item, index) => index).length) {
        allSelected.value = true
      } else {
        allSelected.value = false
      }
    })

    function showHandle() { // for do something when open modal
      indexSelectedFlightToChange.value = []
    }

    // Open Modal Search
    async function handleSearchAirline() {
      store.dispatch('app-reservation/setSelectedFlightIndex', indexSelectedFlightToChangeComputed.value)
      await delay(200)
      this.$bvModal.show('modal-modify-flight-search-trip')
    }

    function handleCloseModal() {
      this.$bvModal.hide('modal-modify-change-flight-select-trip')
    }

    function disableUncheck(index) {
      const selectedFlights = indexSelectedFlightToChange.value
      const bookingData = getBookingData.value

      const isSelectedFlightInvalid = !isEmpty(selectedFlights) && !selectedFlights.includes(index)
      if (isProduction) {
        return isSelectedFlightInvalid
      }

      const isFlightTypeMC = ['MC'].includes(bookingData?.flightType)
      const isSourceInvalid = !['VN1A', 'VN1A_MT'].includes(bookingData?.source)

      if ((isFlightTypeMC || isSourceInvalid) && isSelectedFlightInvalid) {
        return true
      }

      return false
    }

    return {
      getBookingData,
      showHandle,
      handleCloseModal,
      formatCurrency,
      handleSearchAirline,
      indexSelectedFlightToChange,
      indexSelectedFlightToChangeComputed,
      convertISODateTime,
      allSelected,
      toggleAll,
      disableUncheck,
      convertShortTrip,
      isProduction,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.custom-checkbox-label::v-deep {
  .custom-control-label {
    margin-top: 2px !important;
  }
}
</style>
